import { Injectable, signal } from '@angular/core';
import { from, map, switchMap, tap } from 'rxjs';
import WindowUtils from '../../utils/window.utils';
import { ServiceBase } from '../service-base';

export type ImportTrelloBoardPayload = {
  boardId: string;
  companyId: number;
  projectId: number;
  mapping: {
    listId: string;
    columnId: number;
  }[];
  options: {
    archived: boolean;
    attachments: boolean;
  };
};

type TrelloImportResponse = {
  listCount: number;
  cardCount: number;
  viewUrl: string;
};

@Injectable({
  providedIn: 'root',
})
export class TrelloService extends ServiceBase {
  //#region Constants
  private readonly WINDOW_WIDTH = 600;
  private readonly WINDOW_HEIGHT = 600;
  //#endregion

  constructor() {
    super();
  }

  //#region Public Methods
  setOAuth() {
    localStorage.setItem('trello_oauth', JSON.stringify(true));
  }

  clearOAuth() {
    localStorage.removeItem('trello_oauth');
  }

  isLoggedIn(): boolean {
    const item = localStorage.getItem('trello_oauth');

    return item != null;
  }

  getAuthorizationUrl() {
    this.clearOAuth();
    return from(
      this.supabase.client.functions.invoke('get-trello-authorization-url', {
        method: 'GET',
      }),
    ).pipe(
      map((response) => {
        if (response.error || response.data.error) {
          throw new Error(response.data.error);
        }

        return response.data.url as string;
      }),
    );
  }

  getAccessTokens(verifier: string) {
    return from(
      this.supabase.client.functions.invoke('get-trello-access-token', {
        method: 'POST',
        body: {
          verifier,
        },
      }),
    ).pipe(
      map((response) => {
        if (response.error) {
          return false;
        }

        return response.data.result === 'ok';
      }),
      tap((result) => {
        if (!result) {
          this.clearOAuth();
          return;
        }

        this.setOAuth();
      }),
    );
  }

  getBoards() {
    return from(
      this.supabase.client.functions.invoke('get-trello-boards', {
        method: 'POST',
      }),
    ).pipe(
      map((response) => {
        if (response.error) {
          return [];
        }

        return response.data;
      }),
    );
  }

  getLists(boardId: string) {
    return from(
      this.supabase.client.functions.invoke('get-trello-lists', {
        method: 'POST',
        body: {
          boardId,
        },
      }),
    ).pipe(
      map((response) => {
        if (response.error) {
          return [];
        }

        return response.data;
      }),
    );
  }

  getCards(boardId: string) {
    return from(
      this.supabase.client.functions.invoke('get-trello-cards', {
        method: 'POST',
        body: {
          boardId,
        },
      }),
    ).pipe(
      map((response) => {
        if (response.error) {
          return [];
        }

        return response.data;
      }),
    );
  }

  import(payload: ImportTrelloBoardPayload) {
    return from(
      this.supabase.client.functions.invoke('import-trello-board', {
        method: 'POST',
        body: payload,
      }),
    ).pipe(
      map((response) => {
        if (response.error) {
          throw new Error(response.data.error);
        }

        return response.data as TrelloImportResponse;
      }),
    );
  }
  //#endregion

  //#region Dialogs
  showAuthorization() {
    const position = WindowUtils.getCenter({
      width: this.WINDOW_WIDTH,
      height: this.WINDOW_HEIGHT,
    });

    return this.getAuthorizationUrl().pipe(
      switchMap((url) => {
        return WindowUtils.open(url, {
          height: this.WINDOW_HEIGHT,
          width: this.WINDOW_WIDTH,
          ...position,
        });
      }),
    );
  }
  //#endregion
}
